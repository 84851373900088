<template>
    <Sidebar ref="sidebarra" @close="opcion='cerrado'" v-model:visible="visibleside" header="Alarmas" position="right" style="width: 35rem;">
        <h3>Alarmas</h3>
        <div class="direct-chat-messages" style="height: 550px;"> 
            <div class="direct-chat-msg"  v-for="alarma in alarmas" :key="alarma">
                <div class="direct-chat-infos clearfix">
                    <a href="#" style="color:blue" @click="iraservicio(alarma.idservicio); visibleside = false" class="direct-chat-name float-left">{{ alarma.codigo }}</a>
                    <span class="direct-chat-timestamp float-right">{{ alarma.fecha_alarma }}&nbsp;{{alarma.hora_alarma}}</span>
                </div>
                <img class="direct-chat-img" src="https://plataforma.apuyen.app/img/trabajadora.jpg" alt="message user image">
                <div class="direct-chat-text">
                    <p v-html="alarma.texto"></p>
                    <Button style="font-size:0.8rem" @click="opcion = 'Ya hecho';posponer=false" >Ya hecho</Button>&nbsp;&nbsp;<Button @click="opcion = 'Contestar';posponer=false" style="font-size:0.8rem">Contestar</Button>&nbsp;&nbsp;<Button @click="opcion = 'cerrado';posponer=true" style="font-size:0.8rem">Posponer</Button><br><br>
                    <Textarea v-if="opcion != 'cerrado'" v-model="textorespuesta"></Textarea><br>
                    <div class="row"><div class="col-md-2"></div><div><Button v-if="opcion != 'cerrado'" style="font-size:0.8rem;background:green;border:1px solid green" @click="desactivaralarma(alarma,textorespuesta,opcion)">{{ opcion }}</Button></div></div>
                    <div v-if="posponer == true">
                        <div class="row" >Fecha:&nbsp;<Calendar v-model="fechanotapos" :stepMinute="10" showTime hourFormat="24" dateFormat="dd/mm/yy" /></div><br>
                        <div class="row" >Motivo:&nbsp;<Textarea v-model="textoposponer"></Textarea></div><br>
                        <div class="row"><div class="col-md-2"></div><div><Button style="font-size:0.8rem;background:green;border:1px solid green" @click="sacarfecha();posponeralarma(alarma)">Posponer</Button></div></div>
                    </div>
                </div>
            </div>
        </div>
    </Sidebar>
</template>
<script>
import Sidebar from 'primevue/sidebar';
import Textarea from 'primevue/textarea';
import { PwgsApi } from '../services/PwgsApi';
import Calendar from 'primevue/calendar';
export default ({
    props: ['alarmas','visible'],
    setup() {
        return;

    },
    components: {
        Sidebar,
        Textarea,
        Calendar
    },
    data() {
        return {
            visibleside: false,
            opcion: 'cerrado',
            posponer: false,
            fechanotapos:'',
            posponerfecha: '',
            posponerhora: '',
            posponerminuto:'',
            textoposponer:'',
        }
    },
    methods: {
        iraservicio(idruta) {
            this.$router.push('/servicios/' + idruta);
        },
        sacarfecha() {
            this.posponerfecha = new Date(this.fechanotapos).toLocaleDateString("es-ES", { day: 'numeric' }) + "-" + new Date(this.fechanotapos).toLocaleDateString("es-ES", { month: 'numeric' }) + "-" + new Date(this.fechanotapos).toLocaleDateString("es-ES", { year: 'numeric' });
            this.posponerhora = new Date(this.fechanotapos).toLocaleTimeString("es-ES", { hour: '2-digit', minute: '2-digit' });
            this.posponerminuto = new Date(this.fechanotapos).toLocaleTimeString("es-ES", { minute: '2-digit' });
        },
       async desactivaralarma(alrm, txt, opc) {
            const api = new PwgsApi();
           let subidadatos = {};
           if (opc == 'Contestar') { subidadatos = { id_alarma: alrm.id_alarma, texto_alarma: txt }; }
           if (opc == 'Ya hecho') { subidadatos = { id_alarma: alrm.id_alarma }; }
           await api.put('servicios/' + alrm.idservicio + '/notas/' + alrm.id_nota, subidadatos);
           this.visibleside = false;
           this.$router.push('/servicios/' + alrm.idservicio);
       },
        async posponeralarma(alrm) {
            const api = new PwgsApi();
            let subidadatos = { fecha: this.posponerfecha, hora: this.posponerhora, minuto: this.posponerminuto, id_alarma: alrm.id_alarma, texto_alarma: this.textoposponer };
            await api.put('servicios/' + alrm.idservicio + '/notas/' + alrm.idnota, subidadatos);
            this.$router.push('/servicios/' + alrm.idservicio);
        }
    },
    watch: {
        visible() {
            this.opcion = 'cerrado';
             this.visibleside = true;
        }
    },
    mounted() {
       

    },
})



</script>